<template>
  <nav class="quick-links" aria-label="quick links navigation">
    <h2 class="quick-links__heading">Quick Links</h2>
    <ul class="quick-links__list">
      <QuickLinkItem
        v-for="item in quickLinks"
        :key="item.pageId"
        :item="item"
      />
    </ul>
  </nav>
</template>

<script>
import QuickLinkItem from "~/components/FrontPage/QuickLinks/QuickLinkItem";
export default {
  components: {
    QuickLinkItem,
  },
  computed: {
    quickLinks() {
      return this.$store.state.menu.quickLinksMenuData;
    },
  },
};
</script>

<style lang="scss">
.quick-links {
  background-color: get-color("grey", 300);
  width: 100%;
  &__heading {
    @include hidden;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: auto;
    padding: 0.5rem 1rem;
    max-width: $max-width;
    @include breakpoint($max-width) {
      padding: 0.5rem 0;
    }
  }
}
</style>
