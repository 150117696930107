<template>
  <nav class="external-links" aria-label="external links navigation">
    <ul class="external-links__list">
      <!-- <li class="external-links__item external-links__item--hunt-illinois">
        <a href="https://huntillinois.org" class="external-links__link">
          <img
            src="/images/external-links/huntillinois.png"
            class="external-links__image"
            title="Hunt Illinois"
            alt="The Hunt Illinois Logo"
          />
        </a>
      </!-->
      <li class="external-links__item external-links__item--wildlife-illinois">
        <a
          href="https://wildlifeillinois.org"
          target="_blank"
          class="external-links__link"
        >
          <img
            src="/images/external-links/wildlifeillinois.jpg"
            class="external-links__image"
            title="Wildlife Illinois"
            alt="The Wildlife Illinois Logo"
          />
        </a>
      </li>
      <li class="external-links__item external-links__item--hunt-illinois">
        <a
          href="https://huntillinois.org"
          target="_blank"
          class="external-links__link"
        >
          <img
            src="/images/external-links/huntillinois.png"
            class="external-links__image"
            title="Hunt Illinois"
            alt="The Hunt Illinois Logo"
          />
        </a>
      </li>
      <li class="external-links__item external-links__item--hunt-illinois">
        <a
          href="https://outdoor.wildlifeillinois.org"
          target="_blank"
          class="external-links__link"
        >
          <img
            src="/images/external-links/outdoorillinois.jpg"
            class="external-links__image"
            title="Outdoor Illinois Wildlife Journal"
            alt="The Outdoor Illinois Wildlife Journal Logo"
          />
        </a>
      </li>
      <li class="external-links__item external-links__item--cicada-idnr">
        <a
          href="https://cicada-idnr.org"
          target="_blank"
          class="external-links__link"
        >
          <img
            src="/images/external-links/CICADA-small-one-color.jpg"
            class="external-links__image"
            title="Wildlife Illinois"
            alt="The Wildlife Illinois Logo"
          />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "ExternalLinks",
};
</script>

<style lang="scss">
.external-links {
  &__list {
    margin-top: 20px;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    @include tablet {
      flex-direction: row;
    }
    @include mobile {
      margin-left: 12px;
    }
  }
  &__item {
    margin-right: 72px;
    margin-bottom: 68px;
    @include mobile {
      margin-bottom: 20px;
    }
    & + & {
      margin-bottom: 20px;
      @include tablet {
        margin-top: unset;
      }
    }
  }
  &__image {
    width: 214px;
  }
}
</style>
