<template>
  <section v-if="announcement" class="app-announcement">
    <h2 class="app-announcement__title">Announcements</h2>
    {{ announcement.title }}
    <app-button
      size="small"
      color="orange"
      class="app-announcement__link-button"
      @click.native="moreInfo"
      >More Info</app-button
    >
  </section>
</template>

<script>
import { get } from "lodash";
import GetLatestAnnouncement from "~/apollo/queries/getLatestAnnouncement";
import AppButton from "~/components/AppButton/AppButton";
import * as moment from "moment";

function addMonthsUTC(date, count) {
  if (date && count) {
    var m,
      d = (date = new Date(+date)).getUTCDate();

    date.setUTCMonth(date.getUTCMonth() + count, 1);
    m = date.getUTCMonth();
    date.setUTCDate(d);
    if (date.getUTCMonth() !== m) date.setUTCDate(0);
  }
  return date;
}

export default {
  components: {
    AppButton,
  },

  data() {
    return {
      announcement: {},
    };
  },

  apollo: {
    announcement: {
      query: GetLatestAnnouncement,
      variables() {
        let d = new Date();
        d = addMonthsUTC(d, 1);
        const vars = {
          year: d.getFullYear(),
          month: d.getMonth(),
          day: d.getDate(),
        };
        return vars;
      },
      update(data) {
        return get(data, "announcements.nodes[0]");
      },
    },
  },
  methods: {
    moreInfo() {
      // const uri = `/announcement/${this.announcement.uri}`;
      const uri = `/announcement/`;
      window.location = uri;
    },
  },
};
</script>

<style lang="scss">
.app-announcement {
  // @include gotham;
  align-items: center;
  background-color: get-color("orange");
  color: white;
  display: flex;
  font-size: 20px;
  height: 60px;
  justify-content: center;
  padding: 6px 1rem;
  &__title {
    @include hidden;
  }
  &__link-button {
    border: solid white 1px !important;
    margin-left: 1rem;
  }
}
</style>
