<template>
  <section class="app-container">
    <mobile-menu />
    <front-header />
    <quick-links />
    <front-main />
    <Footer />
  </section>
</template>

<script>
import FrontHeader from "~/components/FrontPage/Header/FrontHeader";
import FrontMain from "~/components/FrontPage/FrontMain";
import Footer from "~/components/Footer/Footer";
import MobileMenu from "~/components/MobileMenu/MobileMenu";
import QuickLinks from "~/components/FrontPage/QuickLinks/QuickLinks";

export default {
  components: {
    FrontHeader,
    FrontMain,
    Footer,
    MobileMenu,
    QuickLinks,
  },
  layout: "frontpage",
};
</script>

<style lang="scss">
.app-container {
  @include app-container;
}
</style>
