<template>
  <main class="front-main">
    <TopicAreas />
    <ExternalLinks />
  </main>
</template>

<script>
import TopicAreas from "~/components/FrontPage/TopicAreas/TopicAreas.vue";
import ExternalLinks from "~/components/ExternalLinks/ExternalLinks.vue";

export default {
  name: "FrontMain",
  components: {
    TopicAreas,
    ExternalLinks,
  },
};
</script>

<style lang="scss">
.front-main {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: $max-width;
  padding: 1rem 0 2rem;
  width: 100%;
  @include clear();
  @include desktop() {
    flex-wrap: nowrap;
  }
}
</style>
