<template>
  <nav class="topic-areas" aria-label="topic areas navigation">
    <h2 class="topic-areas__heading">Topic Areas</h2>
      <TopicArea
        v-for="(topic, idx) in siteMenuData"
        :key="topic.uri"
        :topic="topic"
        :idx="idx"
      />
  </nav>
</template>

<script>
import TopicArea from "./TopicArea";
export default {
  components: {
    TopicArea,
  },
  data() {
    return {
      siteMenuData: this.$store.state.menu.siteMenuData,
    };
  },
};
</script>

<style lang="scss">
.topic-areas {
  &__heading {
    @include hidden;
  }
}
</style>
