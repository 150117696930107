<template>
  <li class="quick-link-item">
    <a :href="url" class="quick-link-item__link" :target="item.target">
      <img :src="sourceUrl" :alt="altText" class="quick-link-item__image" />
      <div class="quick-link-item__name">{{ label }}</div>
    </a>
  </li>
</template>

<script>
import { get } from "lodash";

export default {
  props: {
    item: Object,
  },
  computed: {
    url() {
      return this.$props.item.url;
    },
    sourceUrl() {
      return get(this.$props.item, "frontPageIcon.sourceUrl");
    },
    altText() {
      return get(this.$props.item, "frontPageIcon.altText");
    },
    label() {
      return this.$props.item.label;
    },
  },
};
</script>

<style lang="scss">
.quick-link-item {
  text-align: center;
  width: 110px;
  &__link {
    color: get-color("teal", 600);
    text-decoration: none;
    transition: color 300ms;
    &:hover {
      color: get-color("brown");
    }
  }
  &__image {
    height: 54px;
  }
}
</style>
