<template>
  <article class="topic-area">
    <section class="topic-area__image" :style="backgroundImage">
      <h3
        class="topic-area__heading"
        :class="colorOverride"
        v-html="topic.label"
      />
    </section>
    <section class="topic-area__subpages">
      <ul class="topic-area__subpage-list">
        <li
          v-for="subpage in subpages"
          :key="subpage.pageId"
          class="topic-area__subpage-item"
        >
          <a
            class="topic-area__subpage-link"
            :href="subpage.url"
            v-html="subpage.label"
          />
        </li>
      </ul>
    </section>
  </article>
</template>

<script>
export default {
  props: {
    topic: Object,
    idx: Number,
  },
  computed: {
    backgroundImage() {
      return `background-image: url(${this.$props.topic.featuredImage})`;
    },
    subpages() {
      return this.$props.topic.childItems;
    },
    colorOverride() {
      if (this.$props.idx == 2) {
        return "color-override";
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
.color-override {
  color: #537e7f !important;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.2) !important;
}
.topic-area {
  background-color: get-color("teal");
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 240px;
  width: 100%;
  @include tablet {
    flex-direction: row;
  }
  &__image {
    background-size: cover;
    height: 0;
    flex: none;
    margin-bottom: 2.5rem;
    position: relative;
    @include tablet {
      flex: auto;
      height: unset;
      margin-bottom: unset;
      padding: 0.5rem;
      background-image: unset;
    }
  }
  &__heading {
    color: white;
    font-family: $font-serif;
    font-size: 30px;
    margin-left: 1rem;
    margin-top: 0.5rem;
    @include tablet {
      margin-left: unset;
      margin-top: unset;
      position: absolute;
      right: 1rem;
      text-shadow: 0 0 3px transparentize(black, 0.5);
    }
  }
  &__subpages {
    width: calc(200% / 3);
    max-width: 290px;
  }
  &__subpage-list {
    color: white;
    font-size: 20px;
    list-style-type: none;
    margin-left: 0.5rem;
    padding: 0.5rem;
  }
  &__subpage-item {
    line-height: 1.25;
    margin-left: 10px;
    text-indent: -10px;
  }
  &__subpage-link {
    color: currentColor;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media (max-width: 500px) {
  .topic-area:nth-of-type(2) .topic-area__image {
    margin-bottom: 4rem;
  }
}
@media (max-width: 500px) {
  .color-override {
    color: white !important;
    text-shadow: none !important;
  }
}
</style>
