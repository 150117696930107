<template>
  <header class="front-header" role="presentation">
    <section
      class="front-header__top-row"
      role="region"
      aria-label="Front header"
    >
      <SearchWidget />
      <HeaderLogo />
      <SiteNav :menu-data="menuData" />
    </section>
    <div class="front-header__image" />
    <app-announcement />
  </header>
</template>

<script>
import HeaderLogo from "~/components/HeaderLogo/HeaderLogo";
import SearchWidget from "~/components/SearchWidget.vue";
import SiteNav from "~/components/SiteNav/SiteNav.vue";
import AppAnnouncement from "~/components/AppAnnouncement/AppAnnouncement.vue";

export default {
  name: "FrontHeader",
  components: {
    HeaderLogo,
    SearchWidget,
    SiteNav,
    AppAnnouncement,
  },
  props: ["menuData"],
};
</script>

<style lang="scss">
.front-header {
  background-color: white;
  &__top-row {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem 2rem 8px;
    position: relative;
    @include tablet {
      justify-content: space-between;
    }
  }
  &__image {
    background-image: url("/images/header/headerimage-mobile.jpg");
    @include tablet {
      background-image: url("/images/header/headerimage.jpg");
    }
    background-size: cover;
    background-position: center bottom;
    height: 158px;
    width: 100%;
  }
}
</style>
